<template>
	<el-dialog
			top="0" center class="commonDia updateMem" :close-on-click-modal="false"
			:visible.sync="importFile" :show-close="false"
			width="600px" :close-on-press-escape="false">
		<template #title>
			<h1 class="tit">导入</h1>
		</template>
		<el-form label-position="top" ref="form" class="rowsInfo" label-suffix="：" label-width="80px" :model="formMem">
			<div class="flex" >
				<el-form-item label="收费停车场">
					<el-select v-model="formMem.parkId" placeholder="请选择收费停车场" >
						<el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList"
											 :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="办理人">
					<el-input v-model="formMem.handler" placeholder="请输入办理人" ></el-input>
				</el-form-item>
			</div>
			<el-form-item label="导入文件">
				<el-upload multiple
									 ref="upload"
									 class="upload-demo"
									 action="string"
									 name="uploadFile"
									 :limit="1"
									 :file-list="formMem.file"
									 :before-upload="beforeAvatarUpload"
									 :auto-upload="false"
									 :on-success="successUpload"
									 :http-request="handleChange"
                                     accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                    <el-button class="uploadImg" type="primary"><i class="el-icon-plus avatar-uploader-icon"></i>文件上传
					</el-button>
				</el-upload>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="cancel" :disabled="disabled">取 消</el-button>
			<el-button type="primary" @click="importFileBtn" :disabled="disabled">确 定</el-button>
		</div>
	</el-dialog>

</template>

<script>
import { getCarParkInfoList } from "@/api/api";
export default {
	name: "importFile",
	props:{
		uploadUrl:{
			type: String
		}
	},
	data() {
		return {
			disabled:false,
			importFile: false,
			parkingList: [],
			formMem:{
				parkId:'',
				handler:'',
				file:[]
			},
			headToken: localStorage.accessToken,
		};
	},
	mounted() {
		this.getParkingList();
	},
	methods: {
		getParkingList() {
			let params = {
				page: 1,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.parkingList = res.data.rows;
			});

		},
		beforeAvatarUpload(file){
			const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 'application/vnd.ms-excel'
			const isChargPark = this.formMem.parkId !== ''
			if (!isExcel){
				this.$message.error("上传文件只能是xlsx格式")
				return;
			}
			if (!isChargPark) {
				this.$message.error("请先选择停车场")
			}
			return isExcel && isChargPark
		},
		successUpload(response) {
			if (response.code == 200) {
				this.formMem = {
					parkId:'',
					handler:'',
					file:[]
				}
				this.importFile = false
				this.$message.success("导入成功")
				this.disabled = false
				this.$emit("reflush")
			}
		},
		handleChange(param){
			const loading = this.$loading({
				lock: true,
				text: '上传中，请稍后.....',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})
			let formData = new FormData();
			formData.append('uploadFile',param.file)
			formData.append('parkId',this.formMem.parkId)
			formData.append('handler',this.formMem.handler)
			this.$postJson(this.uploadUrl,formData).then(res=>{
				if (res.data.code == 200) {
					this.formMem = {
						parkId: '',
						handler: '',
						file: []
					}
					loading.close();
					this.importFile = false
					this.$message.success("文件正在上传中，请稍后查看")
					this.disabled = false
					this.$emit("reflush")
				}else {
                  loading.close();
                  this.$message.error(res.data.msg)
                }
			})
		},
		importFileBtn(){
            if (this.formMem.parkId == '' || this.formMem.parkId == null){
                this.$message.error("请先选择停车场");
                this.disabled = false;
                return;
            }
            if (this.formMem.handler == '' || this.formMem.handler == null){
                this.$message.error("请先选择办理人");
                this.disabled = false;
                return;
            }
			this.disabled = true
			this.$refs.upload.submit()
		},
		cancel(){
			this.importFile = false
			this.formMem = {
				parkId:'',
                handler: '',
				file:[]
			}
		},
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
.uploadImg {
	width: 138px;
	height: 42px;
	background: #F0F2F8;
	border: none;
	color: #999DAF;

	i {
		margin-right: 5px;
	}
}
</style>
